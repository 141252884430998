

























































































































































import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IBillingReportRequestParams } from "@/interfaces/billingReportRequestParams";
import { IBillingReportRequestResponse } from "@/interfaces/billingReportRequestResponse";
import { IListItem } from "@/interfaces/listItem";
import moment from 'moment';
import ToastService from '@/services/toastService';
import { GtagHelper } from '../../../utilities/GtagHelper';

const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");    
const Billing = namespace("Billing");

@Component({
    components: {
        WizardBaseStep            
    }
})
export default class WizardStepBillingReport extends Vue {

    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly WizardBaseStepTitle!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;
    @Prop({ type: String, default: false }) readonly ReportsForAllAccounts!: string;
    private IsReportsForAllAccounts = false;
    
    public unwatch: any;
    private loading = false;
    private downloadingFile = false;
    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;
    
    private invoiceDateFrom = '';

    private invoiceDateTo = '';

    private dateToErrorMessage = '';
    private dateFromErrorMessage = '';
    private chosenBillingReportFieldsErrorMessage = '';

    private GtagHelper = new GtagHelper();

    private billingReportRequestParams: IBillingReportRequestParams =
    {
        accountNumber: null,
        billingReportAvailableOptions: null,
        billingReportSelectedOptions: null,
        invoiceEndDate: null,
        invoiceStartDate: null,
        previousReportId: null,
        selectedBillingReportOptionValues: null,
        userId: null
    }

    public billingReportTableColumns = [
        {
            key: 'CreatedDate',
            label: 'Date report generated',
            formatter: this.formatDateTime
        },
        {
            key: 'InvoiceStartDate',
            label: 'Invoice date from',
            formatter: this.formatDate
        },
        {
            key: 'InvoiceEndDate',
            label: 'Invoice date to',
            formatter: this.formatDate
        },
        {
            key: 'SelectedBillingReportOptionValues',
            label: ''
        },
        {
            key: 'Delete',
            label: ''
        }];

    private currentlySelectedChosenBillingReportFields: string[]= [];

    private chosenBillingReportFields: IListItem[] = [];

    private currentlySelectedAvailableBillingReportFields: string[] = [];

    public availableBillingReportFields:IListItem[] = [
        {
            value: 'AccountNum',
            text: 'Account Number'
        },
        {
            value: 'InvoiceNum',
            text:'Invoice Number'
        },
        {
            value: 'ConsolidatedInvoiceNum',
            text: 'Consolidated Invoice Number'
        },
        {
            value: 'InvoiceType',
            text: 'Invoice Type'
        },
        {
            value: 'SiteRefNum',
            text: 'Site Ref Number'
        },
        {
            value: 'InvoiceDate',
            text: 'Invoice Date'
        },
        {
            value: 'MeterPoint',
            text: 'Meter Point'
        },
        {
            value: 'CorrFactor',
            text: 'Correction Factor'
        },
        {
            value: 'Cv',
            text: 'Calorific Value'
        },
        {
            value: 'Energy',
            text: 'Energy'
        },
        {
            value: 'UnitPrice',
            text: 'Unit Price'
        },
        {
            value: 'GasCost',
            text: 'Gas Cost'
        },
        {
            value: 'StandingCharge',
            text: 'Standing Charge'
        },
        {
            value: 'ScVat',
            text: 'SC VAT'
        },
        {
            value: 'CommVatContent',
            text: 'Commercial VAT Content'
        },
        {
            value: 'DomVatContent',
            text: 'Domestic VAT Content'
        },
        {
            value: 'Ccl',
            text: 'CCL'
        },
        {
            value: 'CclComVat',
            text: 'CCL Com VAT'
        },
        {
            value: 'Total',
            text: 'Total'
        },
        {
            value: 'SiteRefName',
            text: 'Site Ref Name'
        },
        {
            value: 'EndDate',
            text: 'End Date'
        },
        {
            value: 'Credited',
            text: 'Credited'
        },
        {
            value: 'CreditDate',
            text: 'Credit Date'
        },
        {
            value: 'MeterNum',
            text: 'Meter Number'
        },
        {
            value: 'Consumption',
            text: 'Consumption'
        },
        {
            value: 'CommPercentage',
            text: 'Comm Percentage'
        },
        {
            value: 'AddressLine1',
            text: 'Address Line1'
        },
        {
            value: 'AddressLine2',
            text: 'Address Line2'
        },
        {
            value: 'AddressLine3',
            text: 'Address Line3'
        },
        {
            value: 'AddressLine4',
            text: 'Address Line4'
        },
        {
            value: 'PostCode',
            text: 'Postcode'
        },
        {
            value: 'CurrentReading',
            text: 'Current Reading'
        },
        {
            value: 'CurrentType',
            text: 'Current Type'
        },
        {
            value: 'PreviousReading',
            text: 'Previous Reading'
        },
        {
            value: 'PreviousType',
            text: 'Previous Type'
        },
        {
            value: 'StartDate',
            text: 'Start Date'
        }         
    ];
    
    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedAccount!: string;
                                        
    @Billing.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @Billing.Getter
    private getBillingReports!: IBillingReportRequestResponse[];


    @Billing.Action
    private fetchBillingReports!: (params: { userId: string, accountNumber: string }) => Promise<void>;

    @Billing.Action
    private submitBillingReportRequest!: (params: IBillingReportRequestParams) => Promise<void>;

    @Billing.Action
    private submitBillingReportForAllAccountsRequest!: (params: IBillingReportRequestParams) => Promise<void>;

    @Billing.Action
    private removeGeneratedBillingReport!: (params: { userId: string, accountNumber: string, id: string }) => Promise<boolean>;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;

    beforeMount() {
        this.IsReportsForAllAccounts = this.ReportsForAllAccounts == "true";
    }

    mounted() {
        if (!this.IsReportsForAllAccounts) {
            this.LoadBillingReportData();
        }

        if (this.IsReportsForAllAccounts) {
            this.setMprnSelectionDisabled(true);
            this.setAgreementSelectionDisabled(true);
            this.setAccountSelectionDisabled(true);
        } else {
            this.setMprnSelectionDisabled(true);
            this.setAgreementSelectionDisabled(true);
        }
    }

    beforeDestroy() {
        this.setMprnSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
        this.setAccountSelectionDisabled(false);
        this.unwatch();
    }

    private fileProcess() {

        if (this.getDownloadFileData == null) {
            return null;
        }

        var fileName = this.getDownloadFileData.fileName;

        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }

        this.GtagHelper.Event("LumiDownloadBillingReport", this.getUserId);
    }

    addSelectedBillingFields() {            

        this.currentlySelectedAvailableBillingReportFields.forEach(selectedValue => {
            
            this.selectBillingField(selectedValue);
        })
      
        this.currentlySelectedAvailableBillingReportFields = [];

        if (this.chosenBillingReportFields.length > 0) {
            this.chosenBillingReportFieldsErrorMessage = '';
        }
    }


    selectBillingField(selectedValue: string) {

        var selectedItem: (IListItem | null) = null;

        this.availableBillingReportFields.forEach(availableField => {

            if (availableField.value === selectedValue) {
                selectedItem = availableField;
            }

        });

        if (selectedItem !== null) {

            this.chosenBillingReportFields.push(selectedItem);

            var index = this.availableBillingReportFields.indexOf(selectedItem);
            if (index !== -1) {
                this.availableBillingReportFields.splice(index, 1);
            }
        }
    }

    addAllBillingFields() {
        this.chosenBillingReportFields = this.chosenBillingReportFields.concat(this.availableBillingReportFields);
        
        this.availableBillingReportFields = [];
        this.currentlySelectedAvailableBillingReportFields = [];

        if (this.chosenBillingReportFields.length > 0) {
            this.chosenBillingReportFieldsErrorMessage = '';
        }

    }

    removeAllBillingFields() {
        this.availableBillingReportFields = this.availableBillingReportFields.concat(this.chosenBillingReportFields);

        this.chosenBillingReportFields = [];
        this.currentlySelectedAvailableBillingReportFields = [];

    }

    removeSelectedBillingFields() {

        this.currentlySelectedChosenBillingReportFields.forEach(selectedValue => {

            var selectedItem: (IListItem | null) = null;

            this.chosenBillingReportFields.forEach(chosenField => {

                if (chosenField.value === selectedValue) {
                    selectedItem = chosenField;
                }

            });

            if (selectedItem !== null) {

                this.availableBillingReportFields.push(selectedItem);

                var index = this.chosenBillingReportFields.indexOf(selectedItem);
                if (index !== -1) {
                    this.chosenBillingReportFields.splice(index, 1);
                }
            }
        })

        this.currentlySelectedChosenBillingReportFields = [];
    }

    // taken from stack overflow
    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    moveSelectedFieldUp() {
        this.moveSelectedField(true);
    }

    moveSelectedFieldDown() {
        this.moveSelectedField(false);          
    }

    moveSelectedField(moveUp: boolean) {
        this.currentlySelectedChosenBillingReportFields.forEach(selectedValue => {

            var selectedItem: (IListItem | null) = null;

            this.chosenBillingReportFields.forEach(chosenField => {

                if (chosenField.value === selectedValue) {
                    selectedItem = chosenField;
                }

            });

            if (selectedItem !== null) {                    

                var index = this.chosenBillingReportFields.indexOf(selectedItem);

                if (moveUp) {

                    if (index != 0) {
                        this.array_move(this.chosenBillingReportFields, index, index - 1);
                    }
                }
                else { // moveDown
                    if (index < this.chosenBillingReportFields.length -1 ) {
                        this.array_move(this.chosenBillingReportFields, index, index + 1);
                    }
                }
            }
        })            
    }

    fromDateChanged() {
        this.dateFromErrorMessage = '';
    }

    toDateChanged() {
        this.dateToErrorMessage = '';
    }

    private generateBillingReportFieldsString() : string {
        var billingReportFields = "";

        this.chosenBillingReportFields.forEach(chosenField => {

            billingReportFields += chosenField.value + ",";
        });

        if (billingReportFields.length > 0) {
            billingReportFields = billingReportFields.slice(0, -1);
        }

        return billingReportFields;
    }

    private customValidation() : boolean {

        var validationPassed = true;

        this.dateToErrorMessage = '';
        this.dateFromErrorMessage = '';
        this.chosenBillingReportFieldsErrorMessage = '';
        
        if (this.invoiceDateTo == '') {
            this.dateToErrorMessage = "Date is required";
            validationPassed = false;
        }

        if (this.invoiceDateFrom == '') {
            this.dateFromErrorMessage = "Date is required";
            validationPassed = false;
        }

        var fromDate = moment(this.invoiceDateFrom);
        var toDate = moment(this.invoiceDateTo);
        var currentDate = moment();
       
        if (fromDate.isAfter(toDate)) {
            this.dateToErrorMessage = "Invoice date to is earlier than Invoice date from";
            validationPassed = false;
        }

        if (toDate.isAfter(currentDate)) {
            this.dateToErrorMessage = "This date is in the future, please select an earlier date";
            validationPassed = false;
        }

        
        if (this.IsReportsForAllAccounts && (fromDate.add(1, 'years') < toDate)) {
            this.dateToErrorMessage = "Date range cannot be greater than 12 months";
            validationPassed = false;
        }           

        if (this.chosenBillingReportFields.length == 0) {
            this.chosenBillingReportFieldsErrorMessage = 'Report data is required';
            validationPassed = false;
        }


        return validationPassed;

    }

    public handleSubmitBillingReportRequest(): void {

        if (!this.customValidation()) {
            return;
        }

        this.showDownloadIndictator();

        try {

            var previousReportId: (string | null) = "";

            if (this.getBillingReports != null && this.getBillingReports.length > 0) {
                previousReportId = this.getBillingReports[0].id;
            }

            this.billingReportRequestParams.accountNumber = this.getSelectedAccount;
            this.billingReportRequestParams.userId = this.getUserId;
            this.billingReportRequestParams.selectedBillingReportOptionValues = this.generateBillingReportFieldsString();
            this.billingReportRequestParams.invoiceStartDate = moment(this.invoiceDateFrom).toDate();
            this.billingReportRequestParams.invoiceEndDate = moment(this.invoiceDateTo).toDate();
            this.billingReportRequestParams.previousReportId = previousReportId;

                if (this.IsReportsForAllAccounts) {
                    this.submitBillingReportForAllAccountsRequest(this.billingReportRequestParams).then(() => {
                        this.downloadingFile = false;

                        this.fileProcess();
                    }, (error) => {
                        this.downloadingFile = false;
                        console.error(error);
                    })
                } else {
                    this.submitBillingReportRequest(this.billingReportRequestParams).then(() => {
                        //var result = this.getBillingReportRequestResponse;
                        this.downloadingFile = false;

                        this.fileProcess();
                        this.LoadBillingReportData();

                    }, (error) => {
                        this.downloadingFile = false;
                        console.error(error);
                    })
                }
            } catch (error) {
                this.downloadingFile = false;
                console.error(error);
            }

        }
    
   
    public async LoadBillingReportData() {

        this.loading = true;        

        await this.fetchBillingReports({ accountNumber: this.getSelectedAccount,userId: this.getUserId }).then(() => {

            this.loading = false;
            
        }).catch(reason => {
            this.loading = false;
            console.error(reason);
        });
    }

    private showDownloadIndictator() {
        this.downloadingFile = true;
        this.intervalHandle = setInterval(() => {
            if (this.downloadingFile && this.downloadProgressCounter < this.estimatedMaxDownloadSeconds) {
                this.downloadProgressCounter++;
            }
        }, 1000);
    }
   
    private formatDate(value: Date): string {
       
        return moment(value).format('DD/MM/YYYY');
    }

    private formatDateTime(value: Date): string {

        return moment(value).format('DD/MM/YYYY HH:mm');
    }

    private copyDataSelectionClick(fieldsList:string,invoiceStartDate: string,invoiceEndDate:string) {



        this.removeAllBillingFields();
        this.currentlySelectedAvailableBillingReportFields = [];

        var fields = fieldsList.split(",");

        fields.forEach(selectedField =>
        {
            this.selectBillingField(selectedField);
        });

        if (this.chosenBillingReportFields.length > 0) {
            this.chosenBillingReportFieldsErrorMessage = '';
        }

        this.invoiceDateFrom = invoiceStartDate;
        this.invoiceDateTo = invoiceEndDate;
    }

    private deleteGeneratedReport(id) {
      
        this.removeGeneratedBillingReport({ userId: this.getUserId, accountNumber: this.getSelectedAccount, id: id }).then(() => {                
            this.LoadBillingReportData();
        });
    }
}

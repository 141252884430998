




















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { namespace } from 'vuex-class';

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepBilling extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: '' }) readonly next_step_id!: string[];

    private selectedOption = 0;
    private visible = true;       
   
    public setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);
    }
}
